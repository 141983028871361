import { Link, NavLink } from 'react-router-dom';
import {
  BookAudio,
  Swords,
  Newspaper,
  LibraryBig,
  Medal,
  Radio,
  Puzzle,
  Bell,
  Dices,
  ScrollText,
  BarChart2,
  Bot,
  Users,
} from 'lucide-react';
import { Button } from 'components/ui/button';
import { cn } from 'utils/styleUtils';

interface IProps {
  isSideNavOpen: boolean;
}

const SideNav = ({ isSideNavOpen }: IProps) => {
  const menuItems = [
    {
      title: 'Statistika',
      icon: BarChart2,
      link: '/',
    },
    {
      title: 'Kurslar',
      icon: BookAudio,
      link: '/courses',
    },
    {
      title: "O'qituvchilar",
      icon: Users,
      link: '/teachers',
    },
    {
      title: 'Grandmasterlar',
      icon: Medal,
      link: '/grandmasters',
    },
    {
      title: 'Botlar',
      icon: Bot,
      link: '/grandmaster-bots',
    },
    {
      title: 'Yangiliklar',
      icon: Newspaper,
      link: '/news',
    },
    {
      title: 'Kitoblar',
      icon: LibraryBig,
      link: '/books',
    },
    {
      title: 'Tahlillar',
      icon: ScrollText,
      link: '/review-games',
    },
    {
      title: 'Musobaqalar',
      icon: Swords,
      link: '/tournaments',
    },
    {
      title: 'Jonli efir',
      icon: Radio,
      link: '/live-streams',
    },
    {
      title: 'Boshqotirmalar',
      icon: Puzzle,
      link: '/puzzles',
    },
    {
      title: 'Bildirishnomalar',
      icon: Bell,
      link: '/notifications',
    },

    {
      title: 'MasterBot',
      icon: Bot,
      link: '/module-bots',
    },
  ];

  return (
    <aside
      className={cn(
        { 'w-full': isSideNavOpen },
        'sticky top-0 max-w-72 flex flex-col border-solid border-r-2 h-screen'
      )}
    >
      <header className="z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 p-2">
        <Link
          to="/"
          className={`flex h-10 items-center gap-2 px-4 dark:text-white ${
            isSideNavOpen ? 'justify-start' : 'justify-center'
          }`}
        >
          {isSideNavOpen && <h1 className="font-semibold">UzChess</h1>}
          <Dices />
        </Link>
      </header>
      <div className="flex flex-col gap-1 p-2">
        {menuItems.map((item, index) => (
          <NavLink
            to={item.link}
            key={index}
            className={({ isActive }) =>
              cn({ 'bg-secondary': isActive }, 'dark:text-white rounded block')
            }
          >
            <Button
              variant="ghost"
              className={`w-full ${
                isSideNavOpen ? 'justify-start' : 'justify-center'
              }`}
            >
              <item.icon className="size-5 stroke-[1.3px]" />
              {isSideNavOpen && <span className="ml-3">{item.title}</span>}
            </Button>
          </NavLink>
        ))}
      </div>
    </aside>
  );
};

export default SideNav;
