import { useContext, Suspense, lazy } from 'react';
import { Routes as DOMRoutes, Route, useLocation } from 'react-router-dom';

import MainLayout from 'layout/MainLayout';
import AuthLayout from 'layout/AuthLayout';
import { Toaster } from 'components/ui/toaster';
import { useRefreshToken } from 'modules/auth/hooks/useRefreshToken';
import { AuthContext } from 'providers/auth';
import Loader from 'components/Loader';

const AuthPage = lazy(() => import('pages/Auth'));
const HomePage = lazy(() => import('pages/Home'));
const CoursesPage = lazy(() => import('pages/Courses'));
const ModulesPage = lazy(() => import('pages/Modules'));
const LessonsPage = lazy(() => import('pages/Lessons'));
const QuizzesPage = lazy(() => import('pages/Quizzes'));
const GrandmastersPage = lazy(() => import('pages/Grandmasters'));
const GrandmasterBotsPage = lazy(() => import('pages/GrandmasterBots'));
const NewsPage = lazy(() => import('pages/News'));
const BooksPage = lazy(() => import('pages/Books'));
const AfishaPage = lazy(() => import('pages/Afisha'));
const ReviewGamesPage = lazy(() => import('pages/ReviewGames'));
const LiveStreamsPage = lazy(() => import('pages/LiveStreams'));
const PuzzlesPage = lazy(() => import('pages/Puzzles'));
const NotificationsPage = lazy(() => import('pages/Notifications'));
const TournamentsPage = lazy(() => import('pages/Tournaments'));
const TournamentParticipantsPage = lazy(
  () => import('pages/TournamentParticipants')
);
const TeachersPage = lazy(() => import('pages/Teachers'));
const ModuleBots = lazy(() => import('pages/ModuleBots'));
const UzchessBots = lazy(() => import('pages/UzchessBot/UzchessBot'));

export const Routes = () => {
  const { pathname } = useLocation();
  const { isLoading } = useRefreshToken();
  const { isAuthenticated } = useContext(AuthContext);

  const routes = [
    { path: '/', element: <HomePage /> },
    { path: '/courses', element: <CoursesPage /> },
    { path: '/courses/:courseId', element: <ModulesPage /> },
    { path: '/courses/:courseId/:moduleId', element: <LessonsPage /> },
    {
      path: '/courses/:courseId/:moduleId/:lessonId',
      element: <QuizzesPage />,
    },
    { path: '/grandmasters', element: <GrandmastersPage /> },
    { path: '/grandmaster-bots', element: <GrandmasterBotsPage /> },
    { path: '/news', element: <NewsPage /> },
    { path: '/books', element: <BooksPage /> },
    { path: '/afisha', element: <AfishaPage /> },
    { path: '/review-games', element: <ReviewGamesPage /> },
    { path: '/tournaments', element: <TournamentsPage /> },
    {
      path: '/tournaments/:tournamentId',
      element: <TournamentParticipantsPage />,
    },
    { path: '/teachers', element: <TeachersPage /> },
    { path: '/live-streams', element: <LiveStreamsPage /> },
    { path: '/puzzles', element: <PuzzlesPage /> },
    { path: '/notifications', element: <NotificationsPage /> },
    { path: '/module-bots', element: <ModuleBots /> },
    { path: '/module-bots/:id', element: <UzchessBots /> },
  ];

  return (
    <>
      {pathname === '/statistics' ? (
        <Suspense fallback={<Loader />}>
          <DOMRoutes>
            <Route
              path="/statistics"
              element={
                <div className="container py-10">
                  <HomePage />
                </div>
              }
            />
          </DOMRoutes>
        </Suspense>
      ) : (
        <>
          {isLoading ? null : isAuthenticated ? (
            <MainLayout>
              <Suspense fallback={<Loader />}>
                <DOMRoutes>
                  {routes.map(route => (
                    <Route
                      path={route.path}
                      element={route.element}
                      key={route.path}
                    />
                  ))}
                </DOMRoutes>
              </Suspense>
            </MainLayout>
          ) : (
            <AuthLayout>
              <Suspense fallback={<Loader />}>
                <DOMRoutes>
                  <Route path="/" element={<AuthPage />} />
                  <Route path="*" element={<AuthPage />} />
                </DOMRoutes>
              </Suspense>
            </AuthLayout>
          )}
        </>
      )}
      <Toaster />
    </>
  );
};
